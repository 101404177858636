<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleCancel"
    destroy-on-close
    :title="`${title}排班`"
    size="1112px"
    :track="{
      name_zh: `基础数据/人员管理/分拣线排班/抽屉-${title}`,
      trackName: $route.path
    }"
  >
    <el-form
      ref="ruleForm"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="search-form"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item label="分拣线:" prop="sortingLine">
            <el-select v-model="formData.sortingLine" placeholder="请选择">
              <el-option
                v-for="item in SORTING_LINE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="班次名称:" prop="groupName">
            <el-input v-model="formData.groupName" placeholder="" />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="作业时间区间:" prop="timeRange">
            <el-date-picker
              v-model="formData.timeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="备注:" prop="remark">
            <el-input
              v-model="formData.remark"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="action">
      <div />
      <div class="base">
        <div>
          <p>
            班次总人数:<span>{{ planTotalNum }}</span>
          </p>
        </div>
        <div>
          <p>
            正式工:<span>{{ regularNum }}</span>
          </p>
        </div>
        <div>
          <p>
            临时工:<span>{{ temporaryNum }}</span>
          </p>
        </div>
      </div>
    </div>
    <el-table
      v-loading="loading.getDetail"
      :data="formTable"
      class="table-component"
    >
      <el-table-column
        type="index"
        label="序号"
        width="80"
      />
      <template
        v-for="item in TABLECOLUMN"
        :key="item.prop"
      >
        <el-table-column
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <span v-if="item.ENUM">{{ item.ENUM[scope.row[item.prop]] }}</span>
            <span
              v-else-if="item.prop === DICTIONARY.ACCOUNT"
              :class="{ 'color-f00': !scope.row[item.prop] }"
            >{{ !scope.row[item.prop] ? "" : scope.row[item.prop] }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column
        label="操作"
        width="130"
        fixed="right"
      >
        <template #default="scope">
          <nh-button
            v-if="requestSuccess"
            type="text"
            @click="handleEdit(scope.row)"
          >
            编辑
          </nh-button>
          <nh-button
            v-if="scope.row.staffId"
            type="text"
            @click="handleRemoveRow(scope.row)"
          >
            清空
          </nh-button>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <div class="footer">
        <nh-button type="grey" @click="handleCancel">
          取消
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading.submitForm"
          @click="submitForm"
        >
          保 存
        </nh-button>
      </div>
    </template>
  </nh-drawer>
  <select-staff-dialog ref="selectStaff" @onSelect="handleAddStaff" />
</template>
<script>
import loadingMixin from '@thales/loading';
import SelectStaffDialog from './components/selectStaffDialog.vue';
import { SORTING_LINE } from '../fileds';
import { DICTIONARY, SCHEDULING_EMPTYPE, TABLECOLUMN } from './fileds';
import { schedulePlanDetail, planUpdate } from '../api';
import mixin from './mixin';

export default {
  name: 'Edit',
  components: {
    SelectStaffDialog,
  },
  mixins: [loadingMixin, mixin],
  emits: ['success'],
  data() {
    return {
      SORTING_LINE,
      DICTIONARY,
      SCHEDULING_EMPTYPE,
      TABLECOLUMN,
      requestData: {}, // 由分拣任务-分配预览跳转
      planTotalNum: 0,
      regularNum: 0,
      temporaryNum: 0,
      formData: {},
      initTable: [],
      formTable: [],
      loading: {
        getDetail: false,
        submitForm: false,
      },
      id: null,
      requestSuccess: true,
      drawerVisible: false,
      rules: {
        sortingLine: [{ required: true, message: '请选择分拣线', trigger: 'change' }],
        groupName: [{ required: true, message: '请输入班组名称', trigger: 'blur' }],
        timeRange: [
          {
            required: true,
            message: '请选择时间',
            trigger: 'change',
          },
        ],
      },
    };
  },
  watch: {
    setFormTable: {
      handler(table) {
        this.formTable = table;
        this.setNum();
      },
      immediate: true,
    },
  },
  methods: {
    init(param) {
      this.drawerVisible = true;
      const { requestData } = param;
      this.requestData = requestData;
      this.requestSuccess = false;
      if (param.id) {
        this.title = '编辑';
        this.id = param.id;
        this.getDetail();
      } else {
        this.title = '新增';
      }
    },
    async getDetail() {
      const resp = await schedulePlanDetail({ schedulePlanId: this.id });
      this.formData = {
        sortingLine: resp.sortingLine,
        groupName: resp.groupName,
        timeRange: [resp.beginWorkTime, resp.endWorkTime],
        remark: resp.remark,
      };
      this.initTable = resp.fluentSchedulePlanDetails;
      this.requestSuccess = true;
    },
    handleEdit(row) {
      const allStaffId = [];
      this.formTable.forEach((item) => {
        if (item.staffId && item.staffId !== row.staffId) {
          allStaffId.push(item.staffId);
        }
      });
      this.$refs.selectStaff.init(row, allStaffId);
    },
    handleAddStaff(row) {
      const table = this.formTable.map((item) => {
        if (row.value === item.value) {
          return { ...row, workLocationNo: item.value };
        }
        return item;
      });
      this.formTable = table;
      this.setNum();
    },
    async handleRemoveRow(row) {
      try {
        await this.$confirm('是否清空?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消',
        });
        return;
      }
      this.formTable = this.formTable.map((item) => {
        if (row.value === item.value) {
          return { value: row.value, label: row.label };
        }
        return item;
      });
      this.$message({
        type: 'success',
        message: '删除成功!',
      });
      this.setNum();
    },
    setNum() {
      const filterTable = this.formTable.filter((item) => !!item.staffId);
      this.planTotalNum = filterTable.length;
      let [regularNum = 0, temporaryNum = 0] = [];
      filterTable.forEach((item) => {
        if (item.staffType === DICTIONARY.TEMPORARY) {
          temporaryNum += 1;
        } else {
          regularNum += 1;
        }
      });
      this.regularNum = regularNum;
      this.temporaryNum = temporaryNum;
    },
    async submitForm() {
      await this.$refs.ruleForm.validate();
      const [beginWorkTime, endWorkTime] = this.formData.timeRange;
      const filterTable = this.formTable.filter((item) => item.staffId);
      if (filterTable.length > 0) {
        const data = {
          ...this.formData,
          beginWorkTime,
          endWorkTime,
          schedulerPlanDetails: filterTable,
          id: this.id,
        };
        await planUpdate(data);
        this.$message({ type: 'success', message: '更新成功' });
        if (this.requestData) {
          this.$router.push({
            path: '/out-warehouse/sorting-manage/sorting-task',
            query: {
              requestData: this.requestData,
            },
          });
        } else {
          this.handleCancel();
          this.$emit('success');
        }
      } else {
        this.$message({
          type: 'warning',
          message: '请选择员工',
        });
      }
    },
    handleCancel() {
      this.drawerVisible = false;
      this.filterStaff = '';
      this.planTotalNum = 0;
      this.regularNum = 0;
      this.temporaryNum = 0;
      this.formTable = [];
      this.initTable = [];
      this.formData = {};
      this.id = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-page {
  margin-bottom: 16px;

  .search-form {
    .el-date-editor {
      width: 100%;
    }
  }
}

.color-f00 {
  color: #f00;
}

.action {
  display: flex;
  padding: 15px 0;
  justify-content: space-between;

  .base {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    p {
      margin-right: 20px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        color: #f00;
      }
    }
  }
}

.btn {
  margin-top: 20px;
}

.footer {
  padding-right: 24px;
  line-height: 64px;
  border-top: 1px solid #ebeef5;
  text-align: right;
}
</style>
