import { formatKeyValueObject } from '@/utils/base';

const DICTIONARY = {
  WORK: 'WORK',
  RESIGN: 'RESIGN',
  ACCOUNT: 'account',
  TEMPORARY: 'TEMPORARY',
  STAFF_TYPE: 'staffType',
};
const SCHEDULING_EMPTYPE = [{
  label: '正式工',
  code: 'REGULAR',
  value: 'REGULAR',
}, {
  label: '临时工',
  code: 'TEMPORARY',
  value: 'TEMPORARY',
}];
const SCHEDULING_EMPTYPE_ENUM = formatKeyValueObject(SCHEDULING_EMPTYPE);
const SCHEDULING_TYPE = [{
  label: '在职',
  value: 'WORK',
}, {
  label: '离职',
  value: 'RESIGN',
}];
export {
  DICTIONARY, SCHEDULING_EMPTYPE, SCHEDULING_TYPE,
};
export const TABLECOLUMN = [{
  label: '工位号',
  width: 80,
  prop: 'label',
}, {
  label: '员工工号',
  width: 120,
  prop: 'staffCode',
}, {
  label: '员工姓名',
  prop: 'staffName',
  width: 80,
}, {
  label: '手机号',
  prop: 'telephone',
  width: 120,
}, {
  label: '员工类型',
  prop: 'staffType',
  width: 80,
  ENUM: SCHEDULING_EMPTYPE_ENUM,
}, {
  label: '关联的账号',
  prop: 'account',
  width: 100,
}];
