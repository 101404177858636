import { FormType } from '@/constant/form';

const SCHEDULING_DICTIONARY = {
  SORTING_LINE: 'sortingLine', // 分拣线
};
const SORTING_LINE = [
  {
    label: '1号分拣线',
    value: 'NO1',
  }, {
    label: '2号分拣线',
    value: 'NO2',
  },
];
export {
  SCHEDULING_DICTIONARY, SORTING_LINE,
};

function filterLabel(val, arr) {
  const filterArr = arr.find((item) => item.value === val);
  return filterArr ? filterArr.label : '';
}

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '分拣线',
  prop: 'sortingLine',
  minWidth: 120,
  formatter: (row) => filterLabel(row.sortingLine, SORTING_LINE),
}, {
  label: '班次名称',
  prop: 'groupName',
  minWidth: 120,
}, {
  label: '班次开始时间',
  prop: 'beginTime',
  minWidth: 120,
}, {
  label: '班次结束时间',
  prop: 'endTime',
  minWidth: 120,
}, {
  label: '班次总人数',
  prop: 'planTotalNum',
  minWidth: 120,
}, {
  label: '正式工',
  prop: 'regularNum',
  minWidth: 120,
}, {
  label: '临时工',
  prop: 'temporaryNum',
  minWidth: 120,
}, {
  label: '班次说明',
  prop: 'remark',
  minWidth: 120,
}, {
  label: '操作',
  prop: 'action',
  fixed: 'right',
  width: 120,
}];
export const serchFields = [
  {
    label: '分拣线',
    prop: 'sortingLine',
    component: FormType.SELECT,
    options: SORTING_LINE,
  },
  {
    label: '班次名称',
    prop: 'groupName',
    component: FormType.INPUT,
  },
  {
    label: '班次时间',
    prop: 'timeRange',
    component: FormType.DATE_PICKER,
    componentAttrs: {
      type: 'datetimerange',
      startPlaceholder: '开始日期',
      endPlaceholder: '结束日期',
    },
  },
];

export const ERRTABLECOLUMN = [{
  label: '分拣线',
  prop: 'dockCode',
  width: 80,
}, {
  label: '班次名称',
  prop: 'zoneCode',
  width: 120,
}, {
  label: '班次开始时间',
  prop: 'status',
  width: 120,
}, {
  label: '班次结束时间',
  prop: 'usedStatus',
  width: 120,
}, {
  label: '工位号',
  prop: 'zoneUsed1',
  width: 80,
}, {
  label: '员工工号',
  prop: 'zoneUsed2',
  width: 120,
}, {
  label: '员工姓名',
  prop: 'zoneUsed3',
  width: 120,
}, {
  label: '数据验证',
  prop: 'zoneUsed4',
  width: 80,
},
];
