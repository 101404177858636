<template>
  <div class="select-component">
    <el-dialog
      v-model="dialogVisible"
      title="选择员工"
      custom-class="custom-dialog_75"
      @close="handleClose"
    >
      <div class="action">
        <header-component ref="childHeader" @headleQuery="headleQuery">
          <slot>
            <nh-button @click="handleAddStaff">
              新增员工
            </nh-button>
          </slot>
        </header-component>
      </div>
      <el-table
        ref="multipleTable"
        v-loading="loading.getTableData"
        height="350"
        :data="tableData"
      >
        <el-table-column width="40">
          <template #default="scope">
            <el-radio
              v-model="radioId"
              :label="scope.row.staffId"
              :disabled="disabledArr.includes(scope.row.staffId)"
              @change="headleCheckSelect(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-for="item in TABLECOLUMN"
          :key="item.prop"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :show-overflow-tooltip="true"
        >
          <template #default="scope">
            <span v-if="item.filter">{{ filterLabel(scope.row[item.prop], item.arr) }}</span>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <nh-pagination
        class="margin-top_normal"
        v-bind="pagination"
        @change="handlePaginationChange"
      />
      <template #footer>
        <div>
          <nh-button type="primary" @click="handleConfirm">
            确 定
          </nh-button>
        </div>
      </template>
      <add-staff ref="staffAdd" @submitSuccess="getTableData" />
    </el-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import HeaderComponent from './components/header.vue';
import AddStaff from './components/addStaff.vue';
import TABLECOLUMN from './fileds';
import { getStaffPage } from '../../api';

export default {
  name: 'SelectStaffDialog',
  components: { HeaderComponent, AddStaff },
  mixins: [loadingMixin],
  emits: ['onSelect'],
  data() {
    return {
      TABLECOLUMN,
      dialogVisible: false,
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      formData: {},
      value: null,
      radioId: null,
      tableData: [],
      selectRow: [],
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    init(row, disabledArr) {
      this.dialogVisible = true;
      this.row = row;
      this.radioId = row.staffId || null;
      this.disabledArr = disabledArr;
      this.$nextTick(() => {
        this.$refs.childHeader.handleQuery();
      });
    },
    headleQuery(data) {
      this.formData = data;
      this.getTableData();
    },
    handlePaginationChange(pagination) {
      this.pagination = {
        ...this.pagination,
        ...pagination,
      };
      this.getTableData();
    },
    async getTableData() {
      const resp = await getStaffPage(this.pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        staffId: item.id,
      }));
      this.pagination.total = resp.total;
      this.pagination.page = resp.page;
      this.pagination.size = resp.size;
    },
    headleCheckSelect(row) {
      this.radioId = row.staffId;
      this.selectRow = row;
    },
    handleAddStaff() {
      this.$refs.staffAdd.init();
    },
    handleConfirm() {
      const data = { ...this.row, ...this.selectRow };
      this.$emit('onSelect', data);
      this.close();
    },
    close() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.selectRow = {};
      this.$refs.childHeader.handleReset();
    },
    filterLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
  },
};
</script>
<style lang="scss" scoped>
.icon-70dfe5 {
  color: #70dfe5;
}

.select-component {
  :deep(.el-radio__label) {
    display: none;
  }
}

.action {
  padding: 15px 0;
}

.center {
  text-align: center;
}

.dialog-footer {
  text-align: left;

  .btn {
    text-align: right;
  }
}
</style>
