export default {
  computed: {
    setFormTable() {
      const table = this.$dictionaryFiledMap.WORK_LOCATION_NO_CODE || [];
      if (this.initTable) {
        table.forEach((list, index) => {
          const findItem = this.initTable.find((item) => list.value === item.workLocationNo);
          if (findItem) {
            table[index] = { ...list, ...findItem };
          }
        });
        return table;
      }
      return table;
    },
  },
};
