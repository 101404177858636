<template>
  <div class="header-component">
    <el-form
      ref="ruleForm"
      :inline="true"
      :model="formData"
    >
      <el-form-item label="员工工号：" prop="staffCode">
        <el-input v-model="formData.staffCode" placeholder="" />
      </el-form-item>
      <el-form-item label="员工姓名：" prop="staffName">
        <el-input v-model="formData.staffName" placeholder="" />
      </el-form-item>
      <el-form-item label="员工类型：" prop="staffType">
        <el-select v-model="formData.staffType" placeholder="--请选择--">
          <el-option
            v-for="item in SCHEDULING_EMPTYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="岗位名称：" prop="postName">
        <el-select
          v-model="formData.postName"
          placeholder="请输入关键词搜索"
          filterable
          remote
          :remote-method="handleRemote"
        >
          <el-option
            v-for="item in postNameList"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="button">
      <nh-button @click="handleQuery">
        查询
      </nh-button>
      <nh-button @click="handleReset">
        重置
      </nh-button>
      <slot />
    </div>
  </div>
</template>
<script>
import { SCHEDULING_EMPTYPE, SCHEDULING_TYPE } from '../../fileds';
import { getPositionPage } from '../../../api';

export default {
  name: 'HeaderComponent',
  emits: ['headleQuery'],
  data() {
    return {
      SCHEDULING_EMPTYPE,
      SCHEDULING_TYPE,
      postNameList: [],
      formData: {
        staffCode: null,
        staffName: null,
        staffType: null,
        status: null,
      },
    };
  },
  created() {
    this.$nextTick(() => {
      this.handleRemote();
    });
  },
  methods: {
    async handleRemote(query) {
      const resp = await getPositionPage({ page: 1, size: 10 }, { positionName: query });
      this.postNameList = resp.records.map((item) => ({
        value: item.id,
        label: item.positionName,
      }));
    },
    handleQuery() {
      this.$emit('headleQuery', this.formData);
    },
    handleReset() {
      this.$refs.ruleForm.resetFields();
      this.handleQuery();
    },
  },
};
</script>
<style lang="scss" scoped>
.header-component {
  :deep(.el-input--suffix) .el-input__inner {
    padding-right: 0;
  }
}

</style>
