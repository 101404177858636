<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields"
      @submit="handleSearch"
      @reset="handleSearch"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loadingTable"
      :data="tableData"
      :columns="TABLECOLUMN"
    >
      <template #tableHeaderRight>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '基础数据/人员管理/分拣线排班/[新增]'
          }"
          @click="handleAdd"
        >
          新增
        </nh-button>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '基础数据/人员管理/分拣线排班/[导入]'
          }"
          @click="handleInfoImport"
        >
          导入
        </nh-button>
        <nh-button
          type="primary"
          plain
          :track="{
            trackName: '基础数据/人员管理/分拣线排班/[导出]'
          }"
        >
          导出
        </nh-button>
      </template>
      <template #action="{row}">
        <nh-button type="text" @click="handleEdit(row)">
          编辑
        </nh-button>
        <nh-button type="text" @click="handleCopy(row)">
          复制
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <nh-data-import
    ref="DataImport"
    :err-msg-list="errMsgList"
    :is-upload="isUpload"
    :table-column="ERRTABLECOLUMN"
    :track="{
      name_zh: '基础数据/人员管理/分拣线排班/弹窗-导入',
      trackName: $route.path
    }"
    @upload="warehouseGoodsImport"
    @downLoadTemplate="handleDownTemplate"
    @submitSuccess="handleGoodsImportSubmitSuccess"
    @cancel="handleGoodsImportSubmitSuccess"
    @clearAll="handleGoodsImportClearAll"
  />
  <info-edit ref="infoEdit" @success="getTableData" />
</template>
<script>
import loadingMixin from '@thales/loading';
import InfoEdit from './edit/index.vue';
import {
  SCHEDULING_DICTIONARY, SORTING_LINE, TABLECOLUMN, serchFields, ERRTABLECOLUMN,
} from './fileds';
import { getPlanPage, planCopy, uploadStation } from './api';

const moment = require('moment');

export default {
  name: 'SchedulingPlan',
  components: { InfoEdit },
  mixins: [loadingMixin],
  data() {
    return {
      SCHEDULING_DICTIONARY,
      SORTING_LINE,
      serchFields,
      TABLECOLUMN,
      ERRTABLECOLUMN,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
        schedulePlanCopy: false,
      },
      downURL: '/hope-saas-base-web/excel/station_import.xlsx',
      isUpload: false,
      errMsgList: [],
    };
  },
  computed: {
    loadingTable() {
      return this.loading.getTableData || this.loading.schedulePlanCopy;
    },
  },
  watch: {
    pagination() {
      this.getTableData();
    },
  },

  created() {
    this.getTableData();
  },
  methods: {
    handleSearch(data) {
      const [beginWorkTime, endWorkTime] = data.timeRange || [];
      this.formData = { ...data, beginWorkTime, endWorkTime };
      this.pagination.page = 1;
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await getPlanPage(pagination, this.formData);
      this.tableData = resp.records.map((item) => ({
        ...item,
        beginTime: item.beginWorkTime && moment(item.beginWorkTime).format('YYYY/MM/DD HH:mm:SS'),
        endTime: item.endWorkTime && moment(item.endWorkTime).format('YYYY/MM/DD HH:mm:SS'),
      }));
      this.pagination.total = resp.total;
    },
    handleInfoImport() {
      // this.$refs.info.init();
      this.$refs.DataImport.showDialog();
    },
    handleAdd() {
      this.$refs.infoEdit.init({});
    },
    handleEdit(row) {
      this.$refs.infoEdit.init({ id: row.id });
    },
    async handleCopy(row) {
      try {
        await this.$confirm('是否复制?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        });
      } catch (error) {
        this.$message({
          type: 'info',
          message: '已取消复制',
        });
        return;
      }
      this.schedulePlanCopy(row.id);
    },
    async schedulePlanCopy(id) {
      await planCopy({ id });
      this.$message({
        type: 'success',
        message: '复制成功!',
      });
      this.getTableData();
    },
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
    async warehouseGoodsImport(fd) {
      const errorMsg = await uploadStation(fd);
      this.isUpload = true;
      this.errMsgList = [...this.errMsgList, ...errorMsg.map((item) => ({ errorMsg: item }))];
    },
    handleDownTemplate() {
      window.location.href = this.downURL;
    },
    handleGoodsImportSubmitSuccess() {
      this.handleGoodsImportClearAll();
    },
    handleGoodsImportClearAll() {
      this.isUpload = false;
      this.errMsgList = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.action {
  padding: 15px 0;
}

.cursor_col {
  cursor: pointer;
  color: #06b880;
}

.text-btn:not(:first-of-type) {
  padding-left: 8px;
}
</style>
