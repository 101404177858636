import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 排班计划新增或编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11950
 */
export function planUpdate(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_schedule_plan/upsert', data);
}
/**
 * @description: 排班计划分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11952
 */
export function getPlanPage(params, data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_schedule_plan/page', data, { params });
}
/**
 * @description: 排班计划复制
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11954
 */
export function planCopy(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_schedule_plan/copy', data);
}
/**
 * @description: 编辑时根据排班计划id查看详情接口
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11956
 */
export function schedulePlanDetail(data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_schedule_plan/detail', data);
}

/**
 * @description: 员工 新增或编辑
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11014
 */
export function staffUpsert(data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/upsert', data);
}
/**
 * @description: 员工 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11016
 */

export function getStaffPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/staff/page', data, {
    params,
  });
}
/**
 * @description: 岗位管理 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11278
 */
export function getPositionPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/position/page', data, { params });
}
/**
 * @description: 查询仓库绑定信息 /关联账号
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/13044
 */

export function warehouseUserBind() {
  return wmsPlusHttp.post('/warehouse_foundation/warehouse_user/bind/list', {});
}

/**
 * @description: 导入
 *
 */
export function uploadStation(data) {
  return wmsPlusHttp.post('/hope-saas-base-web/bmsStation/uploadStation', data, { });
}
