const TABLECOLUMN = [
  {
    label: '员工工号',
    width: 50,
    prop: 'staffCode',
  }, {
    label: '员工姓名',
    prop: 'staffName',
    width: 120,
  }, {
    label: '员工类型',
    prop: 'staffTypeStr',
    width: 120,
  }, {
    label: '岗位名称',
    prop: 'postName',
    width: 80,
  }, {
    label: '状态',
    prop: 'statusStr',
    width: 120,
  }, {
    label: '联系电话',
    prop: 'telephone',
    width: 120,
  }, {
    label: '邮箱',
    prop: 'mail',
    width: 120,
  }, {
    label: '关联账号',
    prop: 'account',
    width: 120,
  }];

export default TABLECOLUMN;
