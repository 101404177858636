<template>
  <nh-dialog
    v-model="dialogVisible"
    title="新增员工"
    append-to-body
    @close="handleClose"
  >
    <nh-dialog-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
    >
      <nh-dialog-form-item label="员工工号：" prop="staffCode">
        <el-input v-model="form.staffCode" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="员工姓名：" prop="staffName">
        <el-input v-model="form.staffName" />
      </nh-dialog-form-item>
      <nh-dialog-form-item
        label="岗位："
        prop="postId"
      >
        <el-select v-model="form.postId" placeholder="请选择">
          <el-option
            v-for="item in postNameList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="员工类型：" prop="staffType">
        <el-select v-model="form.staffType" placeholder="请选择">
          <el-option
            v-for="item in STAFF_EMPTYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        v-if="edit"
        label="状态："
        prop="status"
      >
        <el-select v-model="form.status" placeholder="请选择">
          <el-option
            v-for="item in STAFF_TYPE"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item label="联系电话：" prop="telephone">
        <el-input v-model="form.telephone" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="邮箱：" prop="mail">
        <el-input v-model="form.mail" />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="入职日期：" prop="entryDate">
        <el-date-picker
          v-model="form.entryDate"
          type="datetime"
        />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="离职日期：" prop="leaveDate">
        <el-date-picker
          v-model="form.leaveDate"
          type="datetime"
        />
      </nh-dialog-form-item>
      <nh-dialog-form-item label="关联账号：" prop="accountId">
        <el-select
          v-model="form.accountId"
          filterable
          :filter-method="filterAssociated"
          placeholder="请选择"
          :disabled="form.status === STAFF_DICTIONARY.RESIGN"
        >
          <el-option
            v-for="item in filteredAssociatedList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span class="left">{{ item.label }}/{{ item.mobile }}</span>
          </el-option>
        </el-select>
      </nh-dialog-form-item>
      <nh-dialog-form-item
        label="备注："
        prop="remark"
        :span="24"
      >
        <el-input
          v-model="form.remark"
          type="textarea"
          :rows="2"
          placeholder=""
        />
      </nh-dialog-form-item>
    </nh-dialog-form>
    <template #footer>
      <div>
        <nh-button
          @click="onClose"
        >
          取 消
        </nh-button>
        <nh-button
          type="primary"
          :loading="loading.onSubmitForm"
          @click="onSubmitForm"
        >
          确 定
        </nh-button>
      </div>
    </template>
  </nh-dialog>
</template>
<script>
import loadingMixin from '@thales/loading';
import { STAFF_DICTIONARY, STAFF_EMPTYPE, STAFF_TYPE } from './fileds';

import { staffUpsert, getPositionPage, warehouseUserBind } from '../../../api';

const moment = require('moment');

export default {
  name: 'AddStaff',
  mixins: [loadingMixin],
  emits: ['submitSuccess'],
  data() {
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        return callback(new Error('请输入正确的电话号码'));
      }
      return callback();
    };
    return {
      STAFF_DICTIONARY,
      STAFF_EMPTYPE,
      STAFF_TYPE,
      edit: false,
      dialogVisible: false,
      postNameList: [],
      filteredAssociatedList: [],
      associatedList: [],
      form: {
        staffCode: null,
        staffName: null,
        postId: null,
        staffType: null,
        status: null,
        telephone: null,
        mail: null,
        entryDate: null,
        leaveDate: null,
        accountId: null,
        remark: null,
      },
      loading: {
        onSubmitForm: false,
      },
      rules: {
        staffCode: [
          { required: true, message: '请输入员工工号', trigger: 'blur' },
        ],
        staffName: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' },
        ],
        postId: [
          { required: true, message: '请选择岗位', trigger: 'change' },
        ],
        staffType: [
          { required: true, message: '请选择员工类型', trigger: 'change' },
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        telephone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    'form.postId': {
      handler(val) {
        if (val) {
          this.form.postName = this.getLabel(val, this.postNameList);
        } else {
          this.form.postName = null;
        }
      },
    },
    'form.status': {
      handler(val) {
        if (STAFF_DICTIONARY.RESIGN === val) {
          this.form.leaveDate = moment().format();
          this.form.accountId = null;
          this.form.account = null;
        }
      },
    },
    'form.accountId': {
      handler(val) {
        if (val) {
          this.form.account = this.getLabel(val, this.associatedList);
        }
      },
    },
  },
  methods: {
    getLabel(val, arr) {
      const findArr = arr.find((item) => item.value === val);
      return findArr ? findArr.label : '';
    },
    async getPostNameList() {
      const resp = await getPositionPage({ page: 1, size: 1000 }, {});
      this.postNameList = resp.records.map((item) => ({
        value: item.id,
        label: item.positionName,
      }));
    },
    async getAssociatedList() {
      const resolve = await warehouseUserBind();
      this.associatedList = resolve.map((item) => ({
        value: item.userId,
        label: item.realName,
        mobile: item.mobile,
      }));
      this.filteredAssociatedList = [...this.associatedList];
    },
    init(data) {
      this.dialogVisible = true;
      this.getPostNameList();
      this.getAssociatedList();
      this.$nextTick(() => {
        if (data) {
          this.form = { ...this.form, ...data };
        } else {
          this.form = {
            ...this.form,
            entryDate: moment().format(),
          };
        }
        const hasData = !!data;
        this.edit = hasData;
      });
    },
    async onSubmitForm() {
      await this.$refs.ruleForm.validate();
      const data = {};
      if (!this.edit) {
        data.status = STAFF_DICTIONARY.WORK;
      }
      Object.keys(this.form).forEach((item) => {
        if (this.form[item] !== null && this.form[item] !== '') {
          data[item] = this.form[item];
        }
      });
      data.leaveDate = data.leaveDate && moment(data.leaveDate).format('x');
      data.entryDate = data.entryDate && moment(data.entryDate).format('x');
      await staffUpsert(data);
      this.$message({ type: 'success', message: '提交成功' });
      this.$emit('submitSuccess');
      this.onClose();
    },
    filterAssociated(val) {
      if (val) { // val存在
        this.filteredAssociatedList = this.associatedList.filter((item) => {
          if (item.label.includes(val)
          || item.label.toUpperCase().includes(val.toUpperCase())
          || item.mobile.includes(val)
          || item.mobile.toUpperCase().includes(val.toUpperCase())) {
            return true;
          }
          return false;
        });
      } else { // val为空时，还原数组
        this.filteredAssociatedList = [...this.associatedList];
      }
    },
    onClose() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.$refs.ruleForm.resetFields();
    },
  },
};
</script>
<style scoped>
.left {
  float: left;
}
</style>
