import { FormType } from '@/constant/form';

const STAFF_DICTIONARY = {
  WORK: 'WORK',
  RESIGN: 'RESIGN',
};
const STAFF_EMPTYPE = [{
  label: '正式工',
  value: 'REGULAR',
}, {
  label: '临时工',
  value: 'TEMPORARY',
}];
const STAFF_TYPE = [{
  label: '在职',
  value: 'WORK',
}, {
  label: '离职',
  value: 'RESIGN',
}];

export {
  STAFF_DICTIONARY, STAFF_EMPTYPE, STAFF_TYPE,
};

export const serchFields = [
  {
    label: '员工工号',
    prop: 'staffCode',
    componentType: FormType.INPUT,
  }, {
    label: '员工姓名',
    prop: 'staffName',
    componentType: FormType.INPUT,
  }, {
    label: '员工类型',
    prop: 'staffType',
    componentType: FormType.SELECT,
    options: STAFF_EMPTYPE,
  }, {
    label: '状态',
    prop: 'status',
    componentType: FormType.SELECT,
    options: STAFF_TYPE,
  },
];
